import React, {
  ComponentClass,
  FC,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { copyToClipboard } from 'rollun-ts-utils';
import { IRendererProps } from './DefaultRenderer';
import MuiTooltip from '../../../../../../UI/MuiTooltip';
import { isJSON } from 'rollun-ts-utils/dist';
import { Box } from '@material-ui/core';
import MuiIconButton from '../../../../../../UI/MuiIconButton';
import { useInViewport } from 'react-in-viewport';
import LinkControl from './LinkControl';
import { JSONataInteropControl } from '../../../JSONataInterop/JSONataInteropControl';

interface IControlsProps {
  value?: string | string[];
  config?: any;
  onLookup?: (field: string, value: string) => void;
  idField?: string;
  clearItemCell?: (row: any, cellName: string, initialValue: any) => void;
}

/**
 * Higher order component, ads control buttons to cell renderer
 * @param component
 * @param noSearch
 * @param isJson
 * @param isDatetime
 */

function withControls<T extends IRendererProps>(
  component: FunctionComponent<T> | ComponentClass<T>,
  {
    noSearch = false,
    isJson = false,
    isDatetime = false,
    externalLink = false,
  } = {},
): FC<T & IControlsProps> {
  return (props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { inViewport: inView } = useInViewport(ref as any);
    const [controlsVisible, setControlsVisible] = useState(false);
    const [isJsonataOpen, setIsJsonataOpen] = useState(false);
    const [copied, setCopied] = useState<'yes' | 'no' | string>('yes');
    const [selection, setSelection] = useState<string | null | undefined>(null);

    const copy = useCallback(() => {
      if (selection && isDatetime) {
        const parsedDate = Date.parse(selection);

        if (isNaN(parsedDate)) {
          setCopied('Invalid date!');
          setSelection(null);
          return;
        }

        setCopied('yes');
        setSelection(null);
        copyToClipboard(String(parsedDate / 1000));
        return;
      }

      copyToClipboard(props.value);
      setCopied('yes');
    }, [props.value]);

    const mouseLeave = useCallback(() => {
      setCopied('no');
      !isJsonataOpen && setControlsVisible(false);
    }, []);

    const mouseEnter = useCallback(() => {
      setControlsVisible(true);
    }, []);

    const getSelectedText = useCallback(() => {
      if (isDatetime) {
        let sel: Selection | null = null;
        if (window.getSelection) {
          sel = window.getSelection();
        } else if (document.getSelection) {
          sel = document.getSelection();
        } else return;

        if (!sel) {
          return;
        }

        const range = sel?.getRangeAt(0);
        const start = range.startOffset;
        const end = range.endOffset;

        if (range && end > 0) {
          setSelection(
            range.commonAncestorContainer.textContent
              ?.slice(start, end)
              .trim()
              .replace(' ', 'T'),
          );
        }
      }
    }, []);

    return (
      <div ref={ref} style={{ userSelect: 'none' }}>
        {inView ? (
          <div
            style={{ position: 'relative' }}
            onMouseLeave={mouseLeave}
            onMouseEnter={mouseEnter}
            onMouseUp={getSelectedText}
          >
            <div onMouseUp={getSelectedText}>
              {React.createElement(component, props)}
            </div>
            <Box position="absolute" top="-5%" right="0">
              {props?.config?.isClearable &&
                props?.config.hasOwnProperty('initialValue') &&
                controlsVisible && (
                  <MuiIconButton
                    iconName="ban"
                    disabled={
                      !props?.idField || !props.row || !props.row[props.idField]
                    }
                    onClick={() => {
                      props.clearItemCell &&
                        props.clearItemCell(
                          props.row,
                          props.name,
                          props.config.initialValue,
                        );
                    }}
                    color="error"
                    width={13}
                    height={13}
                  />
                )}
              {controlsVisible && externalLink && (
                <LinkControl
                  config={props?.config}
                  value={props?.value}
                  row={props?.row}
                />
              )}
              {!noSearch && controlsVisible && (
                <MuiIconButton
                  iconName="search"
                  onClick={() =>
                    props.onLookup && props.onLookup(props.name, props.value)
                  }
                  width={13}
                  height={13}
                />
              )}
              {controlsVisible && (
                <>
                  <MuiTooltip
                    title={
                      copied === 'yes'
                        ? 'Copied'
                        : copied === 'no'
                        ? ''
                        : copied
                    }
                  >
                    <span>
                      <MuiIconButton
                        color="#000"
                        iconName="copy"
                        width={13}
                        height={13}
                        onClick={copy}
                      />
                    </span>
                  </MuiTooltip>
                </>
              )}
              {isJson && (
                <JSONataInteropControl
                  isVisible={controlsVisible}
                  width={13}
                  height={13}
                  data={
                    typeof props.value === 'object'
                      ? props.value
                      : JSON.parse(isJSON(props.value) ? props.value : '{}')
                  }
                  open={isJsonataOpen}
                  setOpen={setIsJsonataOpen}
                />
              )}
            </Box>
          </div>
        ) : null}
      </div>
    );
  };
}

export default withControls;
