import { Box, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import MuiButton from '../../../UI/MuiButton';
import Axios from 'axios';
import { httpErrorHandlerPromised } from '../../../utils/common.utils';
import { logger } from '../../../utils/logger';
import {
  ImageData,
  ImageUpdateResponse,
  ProblemImage,
  UploadedImage,
} from './types';
import { ImageForm } from './ImageForm';
import { AlertSwitch } from './AlertSwitch';

export const PicturesUpdate = () => {
  const [images, setImages] = useState<ImageData[]>([
    { key: '', url: '', binData: '' },
  ]);
  const [rid, setRid] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);

  console.log('images', images);

  const handleAddImage = () => {
    setImages([...images, { key: '', url: '', binData: '' }]);
  };

  const handleClearData = () => {
    setImages([{ key: '', url: '', binData: '' }]);
  };

  const handleUpdateImage = (
    index: number,
    field: keyof ImageData,
    value: string,
  ) => {
    const updatedImages = [...images];
    updatedImages[index] = { ...updatedImages[index], [field]: value };
    setImages(updatedImages);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError('');
      setSuccess(false);
      setUploadedImages([]);
      console.log('images', images);
      const formattedImages = images.map((image) => ({
        images: [
          {
            ...(image.key && { key: image.key }),
            ...(image.url && { url: image.url }),
            ...(image.binData && {
              binData: image.binData
                .replace('data:image/png;base64,', '')
                .replace('data:image/jpeg;base64,', '')
                .replace('data:image/jpg;base64,', '')
                .replace('data:image/webp;base64,', ''),
            }),
          },
        ],
      }));
      const logImages = formattedImages.map((img) => ({
        images: [
          {
            ...img.images[0],
            binData: (img?.images[0] as ImageData)?.binData?.length,
          },
        ],
      }));
      console.log('PicturesUpdate: logImages', JSON.stringify(logImages));
      logger.info('PicturesUpdate: formattedImages', JSON.stringify(logImages));

      const problems: ProblemImage[] = [];
      const uploaded: UploadedImage[] = [];
      for (const payload of formattedImages) {
        const logPayload = {
          images: payload.images.map((img) => ({
            ...img,
            binData: (img as ImageData)?.binData?.length,
          })),
        };
        console.log('PicturesUpdate: logPayload', JSON.stringify(logPayload));
        logger.info('PicturesUpdate: logPayload', JSON.stringify(logPayload));
        const { data } = await Axios.put<ImageUpdateResponse>(
          `/api/openapi/ProductImages/v1/images/${rid}`,
          payload,
        );
        if (data.data.problem.length > 0) {
          problems.push(...data.data.problem);
        }
        if (data.data.uploaded.length > 0) {
          uploaded.push(...data.data.uploaded);
        }
      }

      if (uploaded.length > 0) {
        setUploadedImages(uploaded);
      }

      if (problems.length > 0) {
        setError(
          problems
            .map((pb) => `Key: ${pb.key}. Error: ${pb.exception}`)
            .join('\n'),
        );
      } else {
        setSuccess(true);
        handleClearData();
      }
    } catch (error) {
      const { text } = await httpErrorHandlerPromised(error);
      setError(text || '');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      marginBottom={5}
    >
      <Typography variant="h4">
        Take pictures or paste URLs and enter RID to update
      </Typography>
      <AlertSwitch
        success={success}
        uploadedImages={uploadedImages}
        error={error}
      />
      <TextField
        value={rid}
        variant="outlined"
        onChange={(e) => setRid(e.target.value)}
        label="RID"
      />
      <Typography variant="h5">Pictures:</Typography>
      {images.map((image, index) => (
        <ImageForm
          key={index}
          image={image}
          index={index}
          onUpdateImage={handleUpdateImage}
          onDeleteImage={handleDeleteImage}
        />
      ))}
      <MuiButton fullWidth onClick={handleAddImage}>
        Add New Picture
      </MuiButton>
      <MuiButton
        type="submit"
        fullWidth
        color="success"
        disabled={
          !images.length ||
          loading ||
          !images.every(
            (img) => rid.length === 5 && (!!img.url || !!img.binData),
          )
        }
        onClick={handleSubmit}
      >
        Submit update
      </MuiButton>
    </Box>
  );
};
