import React, { useState } from 'react';
import { ExpandedItemProps } from '../utils/types';
import {
  firstToUpper,
  httpErrorHandlerPromised,
} from '../../../utils/common.utils';
import { Alert } from '@material-ui/lab';
import { Box, TextField, Typography } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import HttpDatastore from 'rollun-ts-datastore';

export interface GoodsItemInfoProps {
  itemProps: ExpandedItemProps;
  scanningProgressString: string;
  managerNote?: string;
  order: { id: string; supplier: string };
}

const String: React.FC = ({ children }) => (
  <p className="label-goods-prop p-2 d-flex justify-content-between">
    {children}
  </p>
);

const stringOrNotFound = (
  name: string,
  value: string | null | undefined,
  process = (str: string) => str,
) => (value ? process(value) : `${firstToUpper(name)} : not found`);

const ChangeDimensionsButton: React.FC<{ rid: string }> = ({ rid }) => {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [dimensions, setDimensions] = useState({
    length: '',
    width: '',
    height: '',
    weight: '',
  });

  const uploadDimensions = async () => {
    try {
      setIsLoading(true);
      setSuccess(null);

      const ds = new HttpDatastore(
        '/api/datastore/RollunOfficeInventoryCacheDataStore',
      );

      await ds.update({
        rollun_id: rid,
        side_max: dimensions.height,
        side_min: dimensions.length,
        side_middle: dimensions.width,
        weight: dimensions.weight,
      });
      setSuccess(true);
    } catch (e) {
      const { text } = await httpErrorHandlerPromised(e);
      setError(text);
      setSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box width="100%">
      {isEditorOpen && (
        <Box>
          <TextField
            fullWidth
            label="Length"
            value={dimensions.length}
            onChange={(e) =>
              setDimensions((prev) => ({ ...prev, length: e.target.value }))
            }
          />
          <TextField
            fullWidth
            label="Width"
            value={dimensions.width}
            onChange={(e) =>
              setDimensions((prev) => ({ ...prev, width: e.target.value }))
            }
          />
          <TextField
            fullWidth
            label="Height"
            value={dimensions.height}
            onChange={(e) =>
              setDimensions((prev) => ({ ...prev, height: e.target.value }))
            }
          />
          <TextField
            fullWidth
            label="Weight"
            value={dimensions.weight}
            onChange={(e) =>
              setDimensions((prev) => ({ ...prev, weight: e.target.value }))
            }
          />
          {error && <Typography color="error">{error}</Typography>}
          <MuiButton
            color="primary"
            fullWidth
            disabled={
              !(dimensions.length && dimensions.width && dimensions.height) ||
              isLoading
            }
            onClick={uploadDimensions}
          >
            Upload
          </MuiButton>
          {!!success && (
            <Typography
              style={{
                marginTop: '10px',
              }}
            >
              Dimensions updated successfully
            </Typography>
          )}
        </Box>
      )}
      <MuiButton
        color={isEditorOpen ? 'error' : 'primary'}
        fullWidth
        onClick={() => setIsEditorOpen((prev) => !prev)}
      >
        {isEditorOpen ? 'Close' : 'Change Dimensions'}
      </MuiButton>
    </Box>
  );
};

const GoodsItemInfo: React.FC<GoodsItemInfoProps> = ({
  itemProps,
  scanningProgressString,
  managerNote,
  order,
}) => {
  const {
    id,
    MPN,
    brand_id,
    title,
    weight,
    description,
    photo,
    csns,
    isProp65AlertShowed = false,
  } = itemProps;

  const dimensionsString = (['length', 'width', 'height'] as const)
    .map((dimension) => stringOrNotFound(dimension, itemProps[dimension]))
    .join('/');
  return (
    <div>
      {isProp65AlertShowed && (
        <Alert
          severity={'warning'}
          elevation={6}
          variant="filled"
          hidden={false}
          className=""
        >
          <Box display="flex" flexDirection="column">
            <Typography className="">
              This package is prop_65, you need to apply sticker
            </Typography>
          </Box>
        </Alert>
      )}
      <div className="d-flex justify-content-center">
        <div style={{ minWidth: 250 }} className="odd-child-dark-bg">
          <String>
            <b>Rollun ID: </b> {id}
          </String>
          <String>
            <b>Quantity: </b> {scanningProgressString}
          </String>
          {csns.map(({ sr_name, csn }) => (
            <String>
              <b>{`${sr_name} csn`}:</b> {csn}
            </String>
          ))}
          <String>
            <b>MPN:</b> {stringOrNotFound('MPN', MPN)}
          </String>
          <String>
            <b>Brand:</b> {stringOrNotFound('brand', brand_id)}
          </String>
          <String>
            <b>Title:</b> {stringOrNotFound('title', title)}
          </String>
          <String>
            <b>Weight:</b>{' '}
            {stringOrNotFound('weight', weight, (str) => str + ' LBS')}
          </String>
          <String>
            <b>Dimensions L/W/H:</b> {dimensionsString}
          </String>
          {order.supplier === 'RollunOffice' && (
            <ChangeDimensionsButton rid={id} />
          )}
          {managerNote && (
            <String>
              <span style={{ fontSize: '1.25rem', color: '#CC0000' }}>
                <b>Note:</b> {managerNote}
              </span>
            </String>
          )}
          {description && (
            <String>
              <span style={{ fontSize: '1.25rem', color: '#E74C3C' }}>
                <b>Description:</b>
                {description}
              </span>
            </String>
          )}
        </div>
      </div>
      {photo ? (
        <img
          src={photo}
          style={{ maxWidth: 300 }}
          alt={title ? `${title} ${photo}` : 'Item photo'}
        />
      ) : (
        <div className="m-5">No photo</div>
      )}
    </div>
  );
};

export default GoodsItemInfo;
