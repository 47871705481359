import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { UploadedImage } from './types';
import { Alert } from '@material-ui/lab';

interface SuccessAlertProps {
  uploadedImages: UploadedImage[];
}

export const SuccessAlert: React.FC<SuccessAlertProps> = ({
  uploadedImages,
}) => {
  return (
    <Alert
      severity="success"
      elevation={6}
      variant="filled"
      hidden={false}
      style={{
        fontSize: '16px',
        marginBottom: '10px',
      }}
    >
      <Typography
        style={{
          fontSize: '24px',
        }}
      >
        Success
      </Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {uploadedImages.map((image, index) => (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            key={index}
          >
            {uploadedImages.length > 1 && (
              <Typography
                style={{
                  fontSize: '20px',
                }}
              >
                {index + 1}:
              </Typography>
            )}
            <a href={image.processed}>Result</a>
            <a href={image.originalCached}>Original cached</a>
          </Box>
        ))}
      </Box>
    </Alert>
  );
};
