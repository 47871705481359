import TagsEditor from './Cell/Editors/TagsEditor';
import DefaultEditor from './Cell/Editors/DefaultEditor';
import HttpDatastore from 'rollun-ts-datastore';
import { removeFirstSlash } from './TableHeader';
import { replaceSlashWithDash } from '../../../ServiceConstructor/components/EditService/grid/RulesEditor';
import { PUT_RULES_NAME_PREFIX, ROLES_INHERITANCE } from '../../util/constants';

export const getCustomRowHeight = (columns: Array<any>): number => {
  // let maxHeight = 29;
  // for (const { formatter, formatterName } of columns) {
  //   if (!formatter) continue;
  //   let height = 0;
  //   if (formatterName === 'imageLink') {
  //     height = 100;
  //   }
  //   if (height > maxHeight) {
  //     maxHeight = height;
  //   }
  // }
  // return maxHeight;
  return 29;
};

export const getDefaultEditor = (fieldName: string) => {
  if (fieldName.includes('tags')) {
    return TagsEditor;
  }
  return DefaultEditor;
};

export function extractValuesBetweenCurlyBraces(inputString: string) {
  const regex = /{([^}]*)}/g;
  const matches = [];
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  return matches;
}

export function mergeNonNullValues(arr: any[]) {
  return arr.reduce((acc, cur) => {
    Object.keys(cur).forEach((key) => {
      if (!acc[key]) {
        acc[key] = cur[key];
      }

      // for 0 in timestamp column
      if (acc[key] === '0' && cur[key]) {
        acc[key] = cur[key];
      }
    });
    return acc;
  }, {});
}

export interface PatternConfig {
  pattern: string;
  name: string;
}

export interface LinkConfig {
  link: string;
  name: string;
}

export function getLinksFromPatterns(
  patterns: (PatternConfig | string)[] = [],
  row: any,
) {
  return patterns.map((item) => {
    const pattern = typeof item === 'string' ? item : item.pattern;
    let replacedPattern = '';
    const columnNames = extractValuesBetweenCurlyBraces(pattern);
    for (let i = 0; i < columnNames.length; i++) {
      const columnName = columnNames[i];

      const replacingValue = row[columnName];

      if (!replacingValue) return;

      if (!i) {
        replacedPattern = pattern.replace(`{${columnName}}`, replacingValue);
      } else {
        replacedPattern = replacedPattern.replace(
          `{${columnName}}`,
          replacingValue,
        );
      }
    }
    return typeof item === 'string'
      ? replacedPattern
      : { link: replacedPattern, name: item.name };
  });
}

export function cutLinkToFit(link: string, maxLength: number) {
  if (link.length <= maxLength) {
    return link;
  }

  // Remove the 'https://' part if it exists
  const withoutHttp = link.replace(/^https?:\/\//, '');

  // Calculate the length of each half
  const halfLength = Math.floor(maxLength / 2);

  // Determine the starting index for the first half
  const startIndex = maxLength % 2 === 0 ? halfLength - 2 : halfLength - 1;

  // Get the first half and last half of the link
  const firstHalf = withoutHttp.slice(0, startIndex);
  const lastHalf = withoutHttp.slice(-halfLength);

  // Combine both halves with an ellipsis in the middle
  const cutLink = `${firstHalf}...${lastHalf}`;

  return cutLink;
}

interface Resource {
  id: string;
  name: string;
}

interface Rule {
  id: string;
  allow_flag: string;
  privilege_id: string;
  resource_id: string;
  role_id: string;
}

interface Role {
  id: string;
  name: string;
  parent_id: string;
}

export const isEditAllowed = async (datastoreUrl: string) => {
  const defaultDatastorePutRule = [
    {
      role_id: 'user',
      resource_id: 'datastore',
      privilege_id: 'PUT',
      allow_flag: '1',
    },
  ];

  const rules = getRulesByDatastoreUrl(datastoreUrl) || defaultDatastorePutRule;
  const roles = getUserRoles();
  if (!roles) {
    throw new Error('Roles false');
  }
  if (!rules) {
    throw new Error('Rules false');
  }

  const isRolesInherited = areRolesInherited(roles, rules);
  if (isRolesInherited) {
    return true;
  }

  for (let i = 0; i < roles.length; i++) {
    if (rules[i].allow_flag === '1' && roles.includes(rules[i].role_id)) {
      return true;
    }
  }

  return false;
};

export async function getRules(isCacheValid = false) {
  const localStorageKeys = Object.keys(localStorage);
  for (const key of localStorageKeys) {
    if (key.includes(PUT_RULES_NAME_PREFIX) && isCacheValid) {
      return;
    }
  }

  const resourceDataStore = new HttpDatastore<Resource>(
    '/api/datastore/resourceDataStore',
  );
  const ruleDataStore = new HttpDatastore<Rule>('/api/datastore/ruleDataStore');

  const [resources, rules] = await Promise.all([
    resourceDataStore.query(),
    ruleDataStore.query(),
  ]);

  const putRules = rules.filter((rule) => rule?.privilege_id === 'PUT');

  const result: Record<string, Rule[]> = {};
  for (let i = 0; i < resources.length; i++) {
    const putRule = putRules.find(
      ({ resource_id }) => resource_id === resources[i].id,
    );

    if (!putRule) {
      result[resources[i].name] = [
        {
          id: '',
          role_id: '',
          resource_id: '',
          privilege_id: 'PUT',
          allow_flag: '0',
        },
      ];
      continue;
    }

    const value = { ...putRule, name: resources[i].name };
    if (!(resources[i].name in result)) {
      result[resources[i].name] = [value];
    } else {
      result[resources[i].name].push(value);
    }
  }
  Object.entries(result).map(([name, rules]) => {
    localStorage.setItem(PUT_RULES_NAME_PREFIX + name, JSON.stringify(rules));
  });
}

export function checkRolesInheritance(
  roles: string[],
  rules: Rule[],
  baseUser: string,
  inheritedUsers: string[],
) {
  const findRuleByRoleId = (role_id: string) =>
    rules.find((rule) => rule.role_id === role_id);

  const inheritedUsersRules = inheritedUsers
    .map((user) => findRuleByRoleId(user))
    .filter(Boolean) as Rule[];

  const userRule = findRuleByRoleId(baseUser);

  if (!userRule) {
    return false;
  }

  if (userRule.allow_flag !== '1') {
    return false;
  }

  if (!inheritedUsersRules.length) {
    return true;
  }

  for (let i = 0; i < inheritedUsersRules.length; i++) {
    if (
      inheritedUsersRules[i].allow_flag !== '0' &&
      roles.includes(inheritedUsersRules[i].role_id)
    )
      return true;
  }
}

function getRulesByDatastoreUrl(datastoreUrl: string) {
  return JSON.parse(
    localStorage.getItem(
      PUT_RULES_NAME_PREFIX +
        replaceSlashWithDash(removeFirstSlash(datastoreUrl)),
    ) || 'null',
  );
}

function getUserRoles() {
  const user = JSON.parse(
    sessionStorage.getItem('CACHED_USER_IDENTITY') || 'null',
  );
  const roles = user?.role.split(', ');
  return roles;
}

function areRolesInherited(roles: string[], rules: Rule[]) {
  const inheritedRoles = JSON.parse(
    localStorage.getItem(ROLES_INHERITANCE) || 'null',
  ) as null | Record<string, string[]>;

  if (!inheritedRoles) {
    return false;
  }

  const isInherited = Object.entries(inheritedRoles)
    .map(([user, inheritedUsers]) =>
      checkRolesInheritance(roles, rules, user, inheritedUsers),
    )
    .filter(Boolean);

  return !!isInherited.length;
}

export async function getInheritedRoles(isCacheValid = false) {
  const IS_ROLES_INHERITANCE = localStorage.getItem(ROLES_INHERITANCE);
  if (IS_ROLES_INHERITANCE && isCacheValid) return;

  const rolesDataStore = new HttpDatastore<Role>(
    '/api/datastore/roleDataStore',
  );
  const roles = await rolesDataStore.query();
  const inheritedRoles: Record<string, string[]> = {};
  for (let i = 0; i < roles.length; i++) {
    const role = roles[i];
    const parentId = role.parent_id;

    if (!parentId) {
      continue;
    }

    if (!(parentId in inheritedRoles)) {
      inheritedRoles[parentId] = [];
    }

    inheritedRoles[parentId].push(role.name);
  }

  const getRoles = (roleId: string) => {
    if (!inheritedRoles[roleId]) {
      return [];
    }

    const result = [...inheritedRoles[roleId]];
    inheritedRoles[roleId].forEach((parentRole) => {
      result.push(...getRoles(parentRole));
    });

    return result;
  };

  roles.forEach((role) => {
    const roleId = role.id;
    const parentId = role.parent_id;

    if (!parentId) {
      return;
    }

    if (!inheritedRoles[parentId]) {
      inheritedRoles[parentId] = [];
    }

    inheritedRoles[parentId].push(roleId);
  });

  for (const parentId in inheritedRoles) {
    inheritedRoles[parentId] = [...new Set(getRoles(parentId))];
  }

  localStorage.setItem(ROLES_INHERITANCE, JSON.stringify(inheritedRoles));
}
