import React, { useEffect, useRef } from 'react';
import { IRendererProps } from '../DefaultRenderer';
import { useImageLoad } from './hooks/useImageLoad';
import { useTooltip } from './hooks/useTooltip';
import { ImageTooltip } from './components/ImageTooltip';
import { Typography } from '@material-ui/core';
import withControls from '../withControls';

const ImageLinkRenderer: React.FC<IRendererProps> = ({ value }) => {
  const { loadedImages, errorImages } = useImageLoad({ src: value });
  const {
    showTooltip,
    setReferenceElement,
    setPopperElement,
    styles,
    attributes,
    handleMouseEnter,
    handleMouseLeave,
  } = useTooltip();

  const containerRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const parentCell = containerRef.current.closest('.react-grid-Cell__value')
        ?.parentElement;
      if (parentCell) {
        setReferenceElement(parentCell);
      }
    }
  }, []);

  if (!value || value === '-1') {
    return <span>{value || 'Empty'}</span>;
  }

  const imageLoaded = loadedImages[value];
  const imageError = errorImages[value];

  return (
    <span ref={containerRef} style={{ userSelect: 'none' }}>
      <Typography>
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ color: imageError ? '#999' : undefined }}
        >
          {value}
        </a>
      </Typography>
      <ImageTooltip
        show={showTooltip}
        popperRef={setPopperElement}
        styles={styles}
        attributes={attributes}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="d-flex justify-content-center">
          {!imageLoaded && !imageError && <div>Loading...</div>}
          {imageError && <div>Failed to load image</div>}
          {!imageError && (
            <img
              src={value}
              alt=""
              style={{
                maxHeight: 600,
                maxWidth: 400,
                display: imageLoaded ? 'block' : 'none',
              }}
            />
          )}
        </div>
      </ImageTooltip>
    </span>
  );
};

export default withControls(ImageLinkRenderer, {
  noSearch: true,
});
