import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import { logger } from '../../../utils/logger';

interface CameraProps {
  onPhotoTaken: (imageData: string) => void;
  sideLength?: number;
}

const CAMERA_STORAGE_KEY = 'CAMERA_STORAGE_KEY';

export const Camera: React.FC<CameraProps> = ({
  onPhotoTaken,
  sideLength = 800,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [cameraError, setCameraError] = useState('');
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<string>('');
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);

  const requestCameraPermission = async () => {
    try {
      const result = await navigator.mediaDevices.getUserMedia({ video: true });
      logger.info('camera: requestCameraPermission().result', { result });
      console.log('result', result);

      setPermissionGranted(true);
      await getCameras();
    } catch (err) {
      console.error('Error requesting camera permission:', err);
      if (err instanceof Error) {
        setCameraError(err.message);
      } else {
        setCameraError('Failed to get camera permission');
      }
    }
  };

  const getCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      logger.info('camera: getCameras().devices', { devices });
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput',
      );
      logger.info('camera: getCameras().videoDevices', { videoDevices });
      setCameras(videoDevices);

      const savedCameraId = localStorage.getItem(CAMERA_STORAGE_KEY);
      if (savedCameraId) {
        const savedCamera = videoDevices.find(
          (device) => device.deviceId === savedCameraId,
        );
        if (savedCamera) {
          setSelectedCamera(savedCamera.deviceId);
        }
      } else if (videoDevices.length > 0 && !selectedCamera) {
        setSelectedCamera(videoDevices[0].deviceId);
      }
    } catch (err) {
      console.error('Error getting cameras:', err);
      setCameraError('Failed to get camera list');
    }
  };

  const startCamera = async () => {
    logger.info('camera: startCamera()', { selectedCamera });
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }

    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
          width: { ideal: 800 },
          height: { ideal: 800 },
        },
      });

      setStream(newStream);
      if (videoRef.current) {
        videoRef.current.srcObject = newStream;
      }
    } catch (err) {
      console.error('Error starting camera:', err);
      logger.error('camera: startCamera().error', { err });
      if (err instanceof Error) {
        setCameraError(err.message);
      } else {
        setCameraError('Failed to start camera');
      }
    }
  };

  const handleTakePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      console.log('video', {
        video,
        w: video.videoWidth,
        h: video.videoHeight,
      });
      console.log('canvas', canvas);

      const originalSize = Math.min(video.videoWidth, video.videoHeight);
      console.log('originalSize', originalSize);
      const size = Math.min(originalSize, sideLength);
      console.log('size', size);

      canvas.width = size;
      canvas.height = size;

      const context = canvas.getContext('2d');
      console.log('context', context);
      if (context) {
        const xOffset = (video.videoWidth - originalSize) / 2;
        const yOffset = (video.videoHeight - originalSize) / 2;
        console.log('xOffset', xOffset);
        console.log('yOffset', yOffset);

        context.drawImage(
          video,
          xOffset,
          yOffset,
          originalSize,
          originalSize,
          0,
          0,
          size,
          size,
        );
        const dataUrl = canvas.toDataURL('image/png');
        console.log('dataUrl', dataUrl);
        onPhotoTaken(dataUrl);
      }
    }
  };

  const handleNativeCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataUrl = e.target?.result as string;
        onPhotoTaken(dataUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    requestCameraPermission();
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (selectedCamera) {
      startCamera();
    }
  }, [selectedCamera]);

  console.log('cameras', cameras);

  const handleDeviceChange = (event: any) => {
    const deviceId = event.target.value;
    setSelectedCamera(deviceId);
    localStorage.setItem(CAMERA_STORAGE_KEY, deviceId);
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {!!cameraError && (
        <Typography variant="h4" color="error">
          {cameraError} -{' '}
          {!permissionGranted ? 'Reset camera permissions and reload page' : ''}
        </Typography>
      )}
      {!!cameras.length && !!cameras[0].deviceId ? (
        <FormControl fullWidth>
          <InputLabel>Available Cameras</InputLabel>
          <Select
            value={selectedCamera}
            onChange={handleDeviceChange}
            style={{ minWidth: 200 }}
          >
            {cameras.map((camera) => (
              <MenuItem key={camera.deviceId} value={camera.deviceId}>
                {camera.label || `Camera ${camera.deviceId}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography variant="h5" color="error">
          Connect and allow camera usage
        </Typography>
      )}
      <Box
        style={{
          width: '100%',
          maxWidth: `${sideLength}px`,
          margin: '0 auto',
          position: 'relative',
          aspectRatio: '1/1',
          overflow: 'hidden',
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <Box style={{ display: 'flex', gap: '10px' }}>
        <MuiButton
          fullWidth
          variant="contained"
          component="label"
          color="primary"
        >
          Use Native Camera
          <input
            type="file"
            accept="image/*;capture=camera"
            capture="camera"
            style={{ display: 'none' }}
            onChange={handleNativeCapture}
          />
        </MuiButton>
        <MuiButton fullWidth color="primary" onClick={handleTakePhoto}>
          Use Webcam
        </MuiButton>
      </Box>
    </Box>
  );
};
