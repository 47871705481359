import Joi from 'joi';

export const returnOrderSchema = Joi.object({
  mpReturnNumber: Joi.string().min(1),

  mpOrderNumber: Joi.string().min(1).required(),

  mpDateOpened: Joi.string().min(1),

  positions: Joi.array()
    .items({
      quantity: Joi.number().required(),
      name: Joi.string().required(),
      cost: Joi.string().required(),
      article: Joi.string().required(),
      id: Joi.string(),
      dealId: Joi.string(),
      offerId: Joi.string(),
    })
    .min(1),
  mpName: Joi.string().required(),

  tracknumber: Joi.string().min(1),

  labelCreator: Joi.string().valid('MP'),

  carrier: Joi.string().min(1),

  returnLocation: Joi.string().min(1),

  dealStatus: Joi.string().min(1),

  srName: Joi.string().min(1).required(),

  mpRetReason: Joi.string(),

  contentType: Joi.string(),

  resolution: Joi.string(),

  rma: Joi.string(),
});

export const returnAmazonClosedOrderSchema = Joi.object({
  mpOrderNumber: Joi.string().min(1).required(),

  mpDateOpened: Joi.string().min(1),

  positions: Joi.array()
    .items({
      quantity: Joi.number().required(),
      name: Joi.string().required(),
      cost: Joi.string().required(),
      article: Joi.string().required(),
      id: Joi.string(),
      dealId: Joi.string(),
      offerId: Joi.string(),
    })
    .min(1),

  mpName: Joi.string().required(),

  labelCreator: Joi.string().valid('MP'),

  returnLocation: Joi.string().min(1),

  resolution: Joi.string().min(1),

  problem: Joi.string().min(1),

  dealStatus: Joi.string().min(1),

  srName: Joi.string().min(1).required(),

  mpRetReason: Joi.string(),

  contentType: Joi.string(),

  rma: Joi.string(),
});

export const returnAmazonUpdateOrderSchema = Joi.object({
  mpReturnNumber: Joi.string().min(1).required(),

  mpOrderNumber: Joi.string().min(1).required(),

  mpDateOpened: Joi.string().min(1).required(),

  positions: Joi.array().items({
    quantity: Joi.number().required(),
    name: Joi.string().required(),
    cost: Joi.string().required(),
    article: Joi.string().required(),
    id: Joi.string(),
    dealId: Joi.string(),
    offerId: Joi.string(),
  }),

  mpName: Joi.string().required(),

  tracknumber: Joi.string().min(1).required(),

  labelCreator: Joi.string().valid('MP').required(),

  carrier: Joi.string().min(1).required(),

  returnLocation: Joi.string().min(1).required(),

  dealStatus: Joi.string().min(1).required(),

  srName: Joi.string().min(1).required(),

  mpRetReason: Joi.string().required(),

  contentType: Joi.string(),

  resolution: Joi.string(),

  rma: Joi.string(),
});

export const returnEbayOrderSchema = Joi.object({
  mpReturnNumber: Joi.string().min(1).required(),

  mpOrderNumber: Joi.string().min(1).required(),

  mpDateOpened: Joi.string().min(1).required(),

  positions: Joi.array()
    .items({
      quantity: Joi.number().required(),
      name: Joi.string().required(),
      cost: Joi.string().required(),
      article: Joi.string().required(),
      id: Joi.string(),
      dealId: Joi.string(),
      offerId: Joi.string(),
    })
    .min(1)
    .required(),

  mpName: Joi.string().required(),

  tracknumber: Joi.string().min(1).required(),

  labelCreator: Joi.string().valid('MP').required(),

  carrier: Joi.string().min(1).required(),

  returnLocation: Joi.string().min(1).required(),

  dealStatus: Joi.string().min(1).required(),

  srName: Joi.string().min(1).required(),

  mpRetReason: Joi.string().required(),

  contentType: Joi.string(),
});

export const returnAmazonFbaOrderSchema = Joi.object({
  mpReturnNumber: Joi.string().min(1).required(),

  mpOrderNumber: Joi.string().min(1).required(),

  mpDateOpened: Joi.string(),

  positions: Joi.array()
    .items({
      quantity: Joi.number().required(),
      name: Joi.string().required(),
      cost: Joi.string().required(),
      article: Joi.string().required(),
      id: Joi.string(),
      dealId: Joi.string(),
      offerId: Joi.string(),
    })
    .min(1)
    .required(),

  mpName: Joi.string().required(),

  labelCreator: Joi.string().valid('MP').required(),

  dealStatus: Joi.string().min(1).required(),

  mpRetReason: Joi.string().required(),

  contentType: Joi.string(),

  returnLocation: Joi.string().min(1).required(),
});
