import React from 'react';
import { Box, TextField } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import { Camera } from './Camera';
import { ImageData } from './types';

interface ImageFormProps {
  image: ImageData;
  index: number;
  onUpdateImage: (index: number, field: keyof ImageData, value: string) => void;
  onDeleteImage: (index: number) => void;
}

export const ImageForm: React.FC<ImageFormProps> = ({
  image,
  index,
  onUpdateImage,
  onDeleteImage,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <TextField
        value={image.url}
        variant="outlined"
        onChange={(e) => onUpdateImage(index, 'url', e.target.value)}
        label="Image URL(optional, a taken picture has higher priority than a link)"
      />
      <TextField
        value={image.key}
        variant="outlined"
        onChange={(e) => onUpdateImage(index, 'key', e.target.value)}
        label="Key(optional)"
      />

      {image.binData ? (
        <>
          <Box display="flex" justifyContent="center">
            <img
              src={image.binData}
              alt="test"
              style={{
                maxWidth: '640px',
                width: '100%',
                height: 'auto',
                margin: '0 auto',
              }}
            />
          </Box>
          <MuiButton
            fullWidth
            color="warning"
            onClick={() => onUpdateImage(index, 'binData', '')}
          >
            Retake
          </MuiButton>
        </>
      ) : (
        <Camera
          onPhotoTaken={(photo) => onUpdateImage(index, 'binData', photo)}
        />
      )}
      <MuiButton color="error" onClick={() => onDeleteImage(index)}>
        Delete picture
      </MuiButton>
    </Box>
  );
};
