import React from 'react';
import { IRendererProps } from '../DefaultRenderer';
import { useTooltip } from './hooks/useTooltip';
import { useImageLoad } from './hooks/useImageLoad';
import { ImageTooltip } from './components/ImageTooltip';
import { ImageGrid } from './components/ImageGrid';
import withControls from '../withControls';
import { Typography } from '@material-ui/core';

interface IArrayImageLinkRendererProps extends IRendererProps {
  preloadImages?: boolean;
  maxPreloadImages?: number;
  valueToDisplay?: string[];
}

const ArrayImageLinkRenderer: React.FC<IArrayImageLinkRendererProps> = ({
  value: valueProp,
  preloadImages = false,
  maxPreloadImages = 99,
  valueToDisplay,
}) => {
  const value = valueToDisplay || valueProp;
  const { loadedImages, errorImages } = useImageLoad({
    src: value,
    preloadImages,
    maxPreloadImages,
  });

  const {
    showTooltip,
    setReferenceElement,
    setPopperElement,
    styles,
    attributes,
    handleMouseEnter,
    handleMouseLeave,
  } = useTooltip();

  if (!value || !Array.isArray(value) || value.length === 0) {
    return <span>-</span>;
  }

  return (
    <>
      <Typography>
        <a
          href="#"
          onClick={(e) => e.preventDefault()}
          ref={setReferenceElement as any}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {`${value.length} image${value.length > 1 ? 's' : ''}`}
        </a>
      </Typography>
      <ImageTooltip
        show={showTooltip}
        popperRef={setPopperElement}
        styles={styles}
        attributes={attributes}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ImageGrid
          images={value}
          loadedImages={loadedImages}
          errorImages={errorImages}
          preloadImages={preloadImages}
        />
      </ImageTooltip>
    </>
  );
};

export default withControls(ArrayImageLinkRenderer, {
  noSearch: true,
  isJson: true,
});
