import React, { PureComponent, ReactNode } from 'react';
import Limit from 'rollun-ts-rql/dist/nodes/Limit';
import Query from 'rollun-ts-rql/dist/Query';
import Select from 'rollun-ts-rql/dist/nodes/Select';
import { AggregateFunctionNode } from 'rollun-ts-rql';
import _ from 'lodash';
import { LoadingStatusEnum } from '../../utils/common.types';
import HttpDatastore from 'rollun-ts-datastore';
import { noop } from '../../utils/common.utils';
import { QueryStringifier } from 'rollun-ts-rql';
import {
  Box,
  IconButton,
  LinearProgress,
  Typography,
  Select as MuiSelect,
  MenuItem,
  WithStyles,
  withStyles,
  Card,
  Link,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

export interface PaginatorProps extends WithStyles {
  query?: Query;
  pageSizeOptions?: number[];
  currentCount?: number;
  loadingStatus?: LoadingStatusEnum;
  updateCount?: boolean;
  idField?: string;
  reload?: boolean;
  isLocal?: boolean;

  onSetLimitNode?(node: Limit): void;

  shouldUpdatePaginatorCount?: number;

  // add one of dataStoreURL or getCount, to be able to fech count
  // dataStoreURL - has more priority.
  // if no dataStoreURL provided, getCount cb will be used.
  // if no getCount provided - count will be always displayed as 0

  dataStoreURL?: string;
  getCount?: ((q?: Query) => Promise<number> | number) | undefined;
}

interface IState {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  slowCount: boolean;
}

const classes = () => ({
  select: {
    width: 75,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
});

/**
 * Paginator, fetches how many records in datastore with current {query} exists
 * Also ou can control how many, and what page is rendered in Table
 */

class Paginator extends PureComponent<PaginatorProps, IState> {
  private readonly onSetLimitNode: (node: Limit) => void;
  private pageSizeOptions: number[];

  constructor(props: PaginatorProps) {
    super(props);

    this.state = {
      pageSize: this.props.query?.limitNode?.limit || 20,
      pageNumber: 1,
      totalCount: 1,
      slowCount: this.isSlowCountCached(props.dataStoreURL),
    };

    this.onSetLimitNode = props.onSetLimitNode || noop;
    this.pageSizeOptions = props.pageSizeOptions || [
      1,
      5,
      20,
      50,
      100,
      500,
      1000,
    ];
  }

  fetchCount = async (force = false) => {
    const {
      query,
      dataStoreURL,
      isLocal,
      getCount = () => 1,
      idField = 'id',
    } = this.props;

    // just return current count, if 'slowCount' mode enabled
    if (this.state.slowCount && !force) {
      return this.state.totalCount;
    }

    if (!dataStoreURL || isLocal) {
      return getCount();
    }

    try {
      const ds = new HttpDatastore(dataStoreURL, {
        idField,
        timeout: force ? 0 : 5000,
      });

      const countQuery = _.cloneDeep(query) || new Query({});
      countQuery.selectNode = new Select([
        new AggregateFunctionNode('count', idField),
      ]);
      countQuery.limitNode._offset = 0;
      const result = await ds.query(countQuery);

      const count =
        result.length > 1
          ? result.length
          : +(
              result[0][`count(${idField})`] || +result[0][`${idField}->count`]
            );
      this.cacheSlowCount(dataStoreURL, false);
      return count || 1;
    } catch (err) {
      console.log(err);
      const error = err as Error;
      if (error.message && error.message.includes('timeout')) {
        this.setState({ slowCount: true });
        this.cacheSlowCount(dataStoreURL, true);
      }
      return 1;
    }
  };

  cacheSlowCount(tableName: string, slowCount: boolean) {
    if (slowCount) {
      localStorage.setItem(tableName, '');
    } else {
      localStorage.removeItem(tableName);
    }
  }

  isSlowCountCached(tableName: string | undefined) {
    if (!tableName) {
      return false;
    }

    return localStorage.getItem(tableName) !== null;
  }

  updateNewCount = (force = false) => {
    this.fetchCount(force).then((res) => this.setState({ totalCount: res }));
  };

  debouncedUpdateNewCount = _.debounce(this.updateNewCount, 3000);

  componentDidMount(): void {
    this.updateNewCount();
  }

  componentDidUpdate({
    query = new Query({}),
    shouldUpdatePaginatorCount,
  }: PaginatorProps): void {
    const prevQuery = _.cloneDeep(query);
    const currQuery = _.cloneDeep(this.props.query || new Query({}));

    const currShouldUpdatePaginatorCount = this.props
      .shouldUpdatePaginatorCount;

    if (
      currShouldUpdatePaginatorCount &&
      currShouldUpdatePaginatorCount !== shouldUpdatePaginatorCount
    ) {
      this.updateNewCount();
    }

    // delete limit nodes, because change of limit does not affect count
    prevQuery && (prevQuery.limitNode = undefined);
    currQuery && (currQuery.limitNode = undefined);

    if (
      QueryStringifier.stringify(prevQuery) !==
      QueryStringifier.stringify(currQuery)
    ) {
      this.debouncedUpdateNewCount();
    }
  }

  render(): ReactNode {
    const isLoading = this.props.loadingStatus === LoadingStatusEnum.loading;
    if (isLoading) {
      return (
        <Card elevation={1}>
          <LinearProgress />
        </Card>
      );
    }

    const { currentCount, classes } = this.props;
    const { totalCount, slowCount, pageNumber, pageSize } = this.state;

    return (
      <Card elevation={1} className={classes.root}>
        <Box
          display="flex"
          alignItems="center"
          sx={{ marginX: { xs: 0, sm: 2 } }}
        >
          <IconButton
            id="first-page"
            disabled={isLoading}
            onClick={() => this.goToPage(1)}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            id="previous-page"
            disabled={isLoading}
            onClick={() => this.goToPage(pageNumber - 1)}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="body1" id="page-number">
            {pageNumber}
          </Typography>
          <IconButton
            disabled={isLoading}
            id="next-page"
            onClick={() => this.goToPage(pageNumber + 1)}
          >
            <KeyboardArrowRight />
          </IconButton>
          <IconButton
            disabled={isLoading}
            id="last-page"
            onClick={() =>
              this.goToPage(Math.ceil(totalCount / (currentCount || 1)))
            }
          >
            <LastPageIcon />
          </IconButton>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ marginX: { xs: 0, sm: 2 } }}
        >
          <Box marginX={2}>
            <Typography variant="body1">Page size</Typography>
          </Box>
          <MuiSelect
            value={pageSize}
            className={classes.select}
            disabled={isLoading}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              this.changePageSize(parseInt(event.target.value as string, 10));
            }}
          >
            {this.pageSizeOptions.map((pageSize: number, index: number) => (
              <MenuItem value={pageSize} key={index}>
                {pageSize}
              </MenuItem>
            ))}
          </MuiSelect>
          <Box marginX={2}>
            <Typography variant="body1">{this.getPageInfo()}</Typography>
            {slowCount && (
              <Typography color="error" variant="body1">
                Auto count refresh disabled, because it slows table.
                <Link
                  href="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    this.updateNewCount(true);
                  }}
                >
                  Fetch anyway
                </Link>
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    );
  }

  private goToPage(pageNumber: number) {
    const { pageSize: currentPageSize } = this.state;

    if (pageNumber < 1) {
      return;
    }
    const offset = currentPageSize * (pageNumber - 1);
    this.onSetLimitNode(new Limit(currentPageSize, offset));
    this.setState({ pageNumber });
  }

  private changePageSize(pageSize: number) {
    this.setState({ pageSize }, () => {
      this.goToPage(1);
    });
  }

  private getPageInfo(): string {
    const { currentCount = 1 } = this.props;
    const { pageSize: currentPageSize, pageNumber, totalCount } = this.state;
    const startItemNumber = currentPageSize * (pageNumber - 1) + 1; // FIXME: wrong item count when Grid is showing 'No data'
    const endItemNumber = startItemNumber + currentCount - 1;
    return `Showing items ${startItemNumber}-${endItemNumber} of ${totalCount}`;
  }
}

export default withStyles(classes)(Paginator);
