import React from 'react';
import ArrayImageLinkRenderer from './ArrayImageLinkRenderer';
import { IRendererProps } from '../DefaultRenderer';

interface FeaturedImage {
  original: string;
  processed: string;
}

const FeaturedImagesArrayImageLinkRenderer: React.FC<IRendererProps> = (
  props,
) => {
  const transformValue = (value: FeaturedImage[]) => {
    if (!Array.isArray(value)) {
      return [];
    }
    return value.map((image) => image.processed);
  };

  return (
    <ArrayImageLinkRenderer
      {...props}
      value={props.value}
      valueToDisplay={transformValue(props.value as FeaturedImage[])}
    />
  );
};

export default FeaturedImagesArrayImageLinkRenderer;
