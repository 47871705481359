import React, { Component } from 'react';
import { ErrorType } from '../../../../utils/common.types';
import { Button, Dropdown, ErrorView } from '../../../../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { httpErrorHandler } from '../../../../utils/common.utils';
import axios from 'axios';
import { downloadDataWithContentType } from 'rollun-ts-utils/dist';
import MuiIconButton from '../../../../UI/MuiIconButton';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';

interface IState {
  isLoading: boolean;
  error: ErrorType | null;
  showConfirmModal: boolean;
}

interface IProps {
  datastoreUrl: string;
  appName?: string;
}

class DownloadFullTable extends Component<IProps, IState> {
  state: IState = { isLoading: false, error: null, showConfirmModal: false };

  downloadFullTable = async () => {
    this.setState({ isLoading: true });
    const { appName, datastoreUrl } = this.props;
    try {
      const res = await axios.get(datastoreUrl, {
        headers: { Download: 'csv' },
      });

      const fileName = appName
        ? `${appName}_full_table.csv`
        : `${datastoreUrl.replace(/\//g, '_')}_full_table.csv`;
      downloadDataWithContentType(res.data, 'text/csv', fileName);
      this.setState({ showConfirmModal: false });
    } catch (e) {
      httpErrorHandler(e, (code, text) => {
        this.setState({ error: { code, text } });
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, error, showConfirmModal } = this.state;
    if (error) {
      return (
        <Dialog
          open={!!error}
          onClose={() => this.setState({ isLoading: false, error: null })}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <ErrorView error={error} />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => this.setState({ isLoading: false, error: null })}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <>
        <MuiIconButton
          title="Download Full table as CSV"
          color="warning"
          width={40}
          height={40}
          iconName="file-download"
          disabled={isLoading}
          onClick={() => this.setState({ showConfirmModal: true })}
        />

        <Dialog
          open={showConfirmModal}
          onClose={() => this.setState({ showConfirmModal: false })}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Download Full Table</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Warning: For very large tables, the download might not work well.
              If you experience issues after the first try, please don't try
              again, contact developers.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => this.setState({ showConfirmModal: false })}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.downloadFullTable();
              }}
              disabled={isLoading}
            >
              {isLoading ? 'Downloading...' : 'Download'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DownloadFullTable;
