import React from 'react';
import { ErrorAlert } from './ErrorAlert';
import { InfoAlert } from './InfoAlert';
import { SuccessAlert } from './SuccessAlert';
import { UploadedImage } from './types';

interface AlertSwitchProps {
  success: boolean;
  uploadedImages: UploadedImage[];
  error: string;
}

export const AlertSwitch = ({
  success,
  uploadedImages,
  error,
}: AlertSwitchProps) => {
  return (
    <>
      {success && uploadedImages.length ? (
        <SuccessAlert uploadedImages={uploadedImages} />
      ) : uploadedImages.length ? (
        <InfoAlert uploadedImages={uploadedImages} />
      ) : null}
      <ErrorAlert error={error} />
    </>
  );
};
