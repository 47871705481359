export const SENDERS = [
  'rm_ds_a',
  'rm_ds_b',
  'rm_ds_c',
  'rm_ds_d',
  'rm_ds_e',
  'pu_ds_a',
  'pu_ds_b',
  'pu_ds_c',
  'pu_ds_d',
  'pu_ds_e',
  'au_ds_a',
  'pickup_ut',
  'pickup_cn',
  'wps_ds_a',
  'wps_ds_b',
  'wps_ds_c',
  'wps_ds_d',
  'wps_ds_e',
  'tft_ds_a',
  'tft_ds_b',
  'tft_ds_c',
  'tft_ds_d',
  'tft_ds_e',
] as const;

export const bagSenders = ['pickup_ky', 'pickup_ut', 'pickup_cn'];

export type Sender = typeof SENDERS[number];
