import React from 'react';
import { Alert } from '@material-ui/lab';

interface ErrorAlertProps {
  error: string;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ error }) => {
  if (!error) return null;

  return (
    <Alert
      severity={'error'}
      elevation={6}
      variant="filled"
      hidden={false}
      style={{
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '10px',
      }}
    >
      {error}
    </Alert>
  );
};
