import React, { PureComponent, ReactNode, RefObject, createRef } from 'react';
import { Column, GridRowsUpdatedEvent } from 'rollun-react-data-grid';
import NoData from './NoData';
import { ErrorView, Spinner } from '../../../../UI';
import {
  getCustomRowHeight,
  getDefaultEditor,
  mergeNonNullValues,
} from './utils';
import { LoadingStatusEnum } from '../../../../utils/common.types';
import ColumnHeaderCell from './ColumnHeaderCell';
import DefaultRenderer, {
  isValidTimestamp,
} from './Cell/Renderers/DefaultRenderer';
import { ColumnConfig } from '../../util/config.utils';
import { drawerWidth } from '../../../../utils/common.constants';
import {
  EditorContext,
  LeftMenuContext,
} from '../../../../layout/AppContainer/AppContainer';
import { isJSON, isJSONObject } from 'rollun-ts-utils/dist';
import JsonRenderer from './Cell/Renderers/JsonRenderer';
import DatetimeRenderer from './Cell/Renderers/DatetimeRenderer';
import { Box } from '@material-ui/core';
import { noop } from '../../../../utils/common.utils';
import _ from 'lodash';
import { TagsUpdaterParams } from '../../../AbstractService';
import { DataGrid } from './DataGrid';

export interface ColumnsConfig {
  [columnName: string]: ColumnConfig;
}

export interface GridProps {
  maxWidth?: number;
  additionalHeightMinus?: number;
  getSelectedRow?: (selectedRow: any) => void;
  appName?: string;
  data: { [key: string]: any }[];
  columnsConfig?: ColumnsConfig;
  loadingStatus?: LoadingStatusEnum;
  selectedRowIndex?: number;
  error?: { text: string; code: number };
  sortOptions?: Array<{ name: string; direction: -1 | 1 }>;
  minHeight?: number;
  searchOptions?: Array<{ name: string; value: string }>;
  isFiltersShown?: boolean;
  tagsUpdaterParams?: TagsUpdaterParams;
  isEditAllowed?: boolean | null;
  idField?: string;
  clearItemCell?: (row: any, cellName: string, initialValue: any) => void;

  onLookup?(name: string, value: string): void;

  onSelectRow?(rowIndex: number): void;

  onChangeDataValue?(rowIndex: number, cellIndex: number, value: string): void;

  toggleSort?(name: string, currentDirection?: -1 | 1 | null): void;

  toggleLocalSort?(name: string, currentDirection?: -1 | 1 | null): void;

  onSearchFieldChange?(name: string, value: string | null): void;

  onHeaderMenuToggle?(fieldName: string): void;

  tableMinHeight?: number;
}

interface ReactDataGridState {
  columnsConfig: any;
  dynamicHeight: number;
  dynamicWidth: number;
  topLeft?: {
    rowIdx: number;
    colIdx: number;
  } | null;
  botRight?: {
    rowIdx: number;
    colIdx: number;
  } | null;
  selectedWithShift?: {
    startCell: {
      rowIdx: number;
      colIdx: number;
    } | null;
    endCell: {
      rowIdx: number;
      colIdx: number;
    } | null;
  };
  isEventListenerAttached: boolean;
  shiftKeyPressed: boolean;
}

class ReactDataGridAdapter extends PureComponent<
  GridProps,
  ReactDataGridState
> {
  interactionMasksRef: RefObject<any>;

  constructor(props: GridProps) {
    super(props);
    console.log('props', props);

    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.rowGetter = this.rowGetter.bind(this);
    this.interactionMasksRef = createRef();
    this.state = {
      columnsConfig: [],
      dynamicHeight: this.computeHeight(window.innerHeight, window.innerWidth),
      dynamicWidth: this.computeWidth(
        window.innerWidth,
        localStorage.getItem('isLeftMenuOpen') === 'true',
      ),
      isEventListenerAttached: false,
      shiftKeyPressed: false,
    };
  }

  static getDerivedStateFromProps(nextProps: GridProps) {
    const isDataLoaded = nextProps.data && nextProps.data.length > 0;
    const { appName } = nextProps;
    const sessionStorageData = sessionStorage.getItem(`${appName}`);
    if (isDataLoaded && sessionStorageData) {
      const sessionWidthArray: any = JSON.parse(sessionStorageData);
      const newColumnsConfig = ReactDataGridAdapter.getColumns(nextProps);
      newColumnsConfig.forEach((item) => {
        const { key } = item;
        sessionWidthArray.forEach((widthItem: any) => {
          if (widthItem.key === key) {
            item.width = widthItem.width;
          }
        });
      });
      return { columnsConfig: newColumnsConfig };
    } else if (isDataLoaded) {
      return { columnsConfig: ReactDataGridAdapter.getColumns(nextProps) };
    }
    return null;
  }

  private handleResize = () => {
    this.setState({
      dynamicHeight: this.computeHeight(window.innerHeight, window.innerWidth),
      dynamicWidth: this.computeWidth(
        window.innerWidth,
        localStorage.getItem('isLeftMenuOpen') === 'true',
      ),
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  copyValue = () => {
    this.interactionMasksRef.current?.copyValue();
  };

  copyValueWithHeader = () => {
    this.interactionMasksRef.current?.copyValueWithHeader();
  };

  private computeHeight = (height: number, width: number) => {
    return width < 540
      ? height - 480
      : width < 590
      ? height - 430
      : width < 960
      ? height - 400
      : height - 380;
  };

  private computeWidth = (width: number, isOpen: boolean) => {
    if (this.props.maxWidth) {
      return this.props.maxWidth - (isOpen ? drawerWidth / 2 : 0);
    }

    if (width < 1280) {
      return width - 42;
    }

    return (isOpen ? width - drawerWidth : width) - 42;
  };

  computeTableHeight = () => {
    const { tableMinHeight = 400 } = this.props;
    const additionalMinus = this.props.additionalHeightMinus
      ? this.props.additionalHeightMinus
      : 0;

    const filtersShownHeightMinus = this.props.isFiltersShown ? 50 : 0;

    const height =
      this.state.dynamicHeight - additionalMinus - filtersShownHeightMinus;
    return height > tableMinHeight ? height : tableMinHeight;
  };

  static getColumnType({
    value,
    key,
    renderer,
    onLookup,
    idField,
    clearItemCell,
  }: {
    value: any;
    key: string;
    renderer: any;
    onLookup: any;
    idField: string;
    clearItemCell?: (row: any, cellName: string, initialValue: any) => void;
  }) {
    if (renderer) {
      return (props: any) =>
        React.createElement(renderer, {
          onLookup,
          idField,
          name: key,
          clearItemCell,
          ...props,
        });
    }

    const makeCustomRenderer = (reactComponent: React.ComponentType<any>) => {
      return (props: any) =>
        React.createElement(reactComponent, {
          name: key,
          onLookup,
          ...props,
        });
    };

    const isObject = value && typeof value === 'object';

    if (isObject || isJSONObject(value)) {
      return makeCustomRenderer(JsonRenderer);
    }

    if (value && isValidTimestamp(value, key)) {
      return makeCustomRenderer(DatetimeRenderer);
    }

    return makeCustomRenderer(DefaultRenderer);
  }

  private static makeColumnConfig(gridProps: GridProps) {
    const {
      columnsConfig,
      sortOptions = [],
      searchOptions = [],
      onLookup = noop,
      onHeaderMenuToggle = noop,
      onSearchFieldChange = noop,
      toggleSort = noop,
      toggleLocalSort = noop,
      isEditAllowed,
      idField = '',
      clearItemCell,
    } = gridProps;
    const findOption = (key: string) => (option: { name: string }) =>
      option.name === key;

    return (entry: [string, any]): Column<any> & { formatterName: string } => {
      const [key, value] = entry;
      const columnConfig = (columnsConfig && columnsConfig[key]) || {};
      const sortOption = sortOptions.find(findOption(key));
      const searchOption = searchOptions.find(findOption(key));

      const {
        editor = getDefaultEditor(key) as any,
        label = key,
        minWidth,
        rendererName = '',
        isEditable: isColumnEditable = true,
        renderer,
      } = columnConfig;

      const editable = !!isEditAllowed && isColumnEditable && idField !== key;

      const rendererType = this.getColumnType({
        value,
        key,
        renderer,
        onLookup,
        idField,
        clearItemCell,
      });

      return {
        key,
        name: label,
        width: minWidth,
        editable,
        resizable: true,
        editor: editable ? editor : null,
        formatter: rendererType,
        formatterName: rendererName,
        headerRenderer: (props: any) => (
          <ColumnHeaderCell
            {...props}
            name={key}
            onHeaderMenuToggle={onHeaderMenuToggle}
            searchOption={searchOption}
            onSearchFieldChange={onSearchFieldChange}
            sortDirection={sortOption ? sortOption.direction : null}
            toggleSort={toggleSort}
            toggleLocalSort={toggleLocalSort}
          />
        ),
      };
    };
  }

  protected static getColumns(props: GridProps): Column<any>[] {
    const mergedObject = mergeNonNullValues(props.data);
    const tableDataEntries = Object.entries(mergedObject);
    return tableDataEntries.map(this.makeColumnConfig(props));
  }

  renderSwitch(status: LoadingStatusEnum = LoadingStatusEnum.loaded) {
    const { tagsUpdaterParams = { enableTagsUpdater: false } } = this.props;

    switch (status) {
      case 'empty':
        return <NoData>Empty</NoData>;
      case 'loading':
        return (
          <div
            style={{
              minHeight: this.state.dynamicHeight,
              maxHeight: this.state.dynamicHeight,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Spinner />
          </div>
        );
      case 'loaded':
        let maxHeight = 35;
        for (let i = 0, length = this.props.data.length; i < length; i++) {
          const height = getCustomRowHeight(this.state.columnsConfig);
          if (height > maxHeight) {
            maxHeight = height;
          }
        }

        return (
          <EditorContext.Provider
            value={{
              tagsUpdaterParams,
            }}
          >
            <LeftMenuContext.Consumer>
              {(props) => (
                <div
                  style={{
                    maxHeight: this.computeTableHeight(),
                    maxWidth: this.computeWidth(
                      window.innerWidth,
                      props.isOpen,
                    ),
                  }}
                >
                  <DataGrid
                    rowKey="test"
                    headerFiltersHeight={10}
                    rowHeight={maxHeight}
                    headerRowHeight={40}
                    minColumnWidth={180}
                    rowsCount={this.props.data.length}
                    rowGetter={this.rowGetter.bind(this)}
                    columns={this.state.columnsConfig}
                    onGridRowsUpdated={this.handleRowUpdate.bind(this)}
                    enableCellSelect
                    minWidth={this.computeWidth(
                      window.innerWidth,
                      props.isOpen,
                    )}
                    minHeight={this.computeTableHeight()}
                    onColumnResize={(idx, width) =>
                      this.setColumnsWidth(idx, width)
                    }
                    onCellSelected={({ rowIdx }) => {
                      this.handleSelectedRowIndex(this.props.data[rowIdx]);
                    }}
                    // @ts-expect-error
                    cellRangeSelection={{
                      onComplete: () => {
                        console.log('onComplete');
                      },
                    }}
                    interactionMasksRef={this.interactionMasksRef}
                  />
                </div>
              )}
            </LeftMenuContext.Consumer>
          </EditorContext.Provider>
        );
      case 'error':
        const error = this.props.error
          ? this.props.error
          : {
              text: 'Unknown error',
              code: -1,
            };
        return (
          <div
            style={{
              minHeight: this.computeTableHeight(),
              maxHeight: this.computeTableHeight(),
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <ErrorView error={error} />
          </div>
        );
      default:
        return <NoData>Like, idk how you find this text, but whatever</NoData>;
    }
  }

  render(): ReactNode {
    return (
      <Box marginTop={1} marginBottom={1}>
        {this.renderSwitch(this.props.loadingStatus)}
      </Box>
    );
  }

  protected handleSelectedRowIndex(selectedRow: Record<string, unknown>) {
    const { getSelectedRow } = this.props;
    getSelectedRow && getSelectedRow(selectedRow);
  }

  private isPrimitive(value: unknown, prevValue: unknown) {
    if (typeof value === 'object') {
      return false;
    }

    if (
      typeof prevValue === 'number' &&
      typeof value === 'number' &&
      value < Number.MAX_SAFE_INTEGER &&
      value > Number.MIN_SAFE_INTEGER
    ) {
      return false;
    }

    if (
      typeof prevValue === 'string' &&
      typeof value === 'string' &&
      typeof +value === 'number'
    ) {
      return true;
    }

    if (typeof value === 'string' && isJSON(value)) {
      return false;
    }

    return true;
  }

  protected handleRowUpdate(event: GridRowsUpdatedEvent): void {
    const { data, onChangeDataValue } = this.props;
    const { fromRow, updated, cellKey, toRow } = event;
    const rowKeys = Object.keys(data[0]);
    const [updatedKey, rawUpdatedValue] = Object.entries(updated)[0];
    const updatedValue =
      rawUpdatedValue && typeof rawUpdatedValue === 'string'
        ? rawUpdatedValue.trim()
        : rawUpdatedValue;
    const cellIndex = rowKeys.indexOf(updatedKey);
    let prevValue = data[toRow][cellKey];
    let isPrevValueChanged = false;
    if (!prevValue) {
      for (let i = 0; i < data.length; i++) {
        if (!!data[i][cellKey]) {
          isPrevValueChanged = true;
          prevValue = data[i][cellKey];
          break;
        }
      }
    }

    console.log({
      fromRow,
      updated,
      cellKey,
      toRow,
      rowKeys,
      updatedKey,
      updatedValue,
      cellIndex,
      prevValue,
    });
    const isPrimitive = this.isPrimitive(updatedValue, prevValue);

    console.log(isPrimitive, updatedValue);
    const isPrimitivesEqual = (value1: any, value2: any) =>
      !isPrevValueChanged && JSON.stringify(value1) === JSON.stringify(value2);
    const isObjectsEqual = (value1: any, value2: any) => {
      console.log(value1, value2);
      !isPrevValueChanged && _.isEqual(value1, value2);
    };
    const updatedValueWithCorrectType =
      cellKey === 'mg_photo_uris' && typeof updatedValue === 'string'
        ? JSON.parse(updatedValue)
        : !isPrimitive && typeof updatedValue === 'string' && cellKey !== 'tags'
        ? JSON.parse(updatedValue)
        : updatedValue;

    if (
      !(isPrimitive
        ? isPrimitivesEqual(prevValue, updatedValue)
        : isObjectsEqual(prevValue, updatedValueWithCorrectType))
    ) {
      onChangeDataValue &&
        onChangeDataValue(
          fromRow,
          cellIndex,
          updatedValueWithCorrectType as string,
        );
    }
  }

  protected rowGetter(index: number) {
    return this.props.data[index];
  }

  protected setColumnsWidth(idx: number, width: number) {
    const { appName } = this.props;
    const sessionStorageJson = sessionStorage.getItem(`${appName}`);
    let sessionStorageData;
    sessionStorageJson
      ? (sessionStorageData = JSON.parse(sessionStorageJson))
      : (sessionStorageData = []);

    const newKeyItem = this.state.columnsConfig[idx].key;
    let newSessionStorageData = [];

    if (sessionStorageData.length > 0) {
      newSessionStorageData = sessionStorageData.filter(
        (item: any) => item.key !== newKeyItem,
      );
      newSessionStorageData.push({
        key: newKeyItem,
        width: width < 250 ? 250 : width,
      });
    } else {
      newSessionStorageData.push({
        key: newKeyItem,
        width: width < 250 ? 250 : width,
      });
    }

    sessionStorage.setItem(`${appName}`, JSON.stringify(newSessionStorageData));
  }
}

ReactDataGridAdapter.contextType = LeftMenuContext;
export default ReactDataGridAdapter;
