import { useState, useRef } from 'react';
import { usePopper } from 'react-popper';

export const useTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          fallbackPlacements: ['left', 'right', 'top', 'bottom'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
        },
      },
    ],
  });

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowTooltip(true);
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    console.log({ popperElement, relatedTarget: e?.relatedTarget });

    if (
      popperElement &&
      e?.relatedTarget !== window &&
      !popperElement?.contains(e?.relatedTarget as Node)
    ) {
      timeoutRef.current = setTimeout(() => {
        setShowTooltip(false);
      }, 100);
    }
  };

  return {
    showTooltip,
    setReferenceElement,
    setPopperElement,
    styles,
    attributes,
    handleMouseEnter,
    handleMouseLeave,
  };
};
