import React from 'react';
import { BarcodeScanner } from '../../lib/barcode-scanner/BarcodeScanner';

const TestScanner = () => {
  const handleScanResult = (code: string, type: string) => {
    console.log('Scanned code:', code);
    console.log('Barcode type:', type);
  };

  return (
    <div style={{ margin: '0 auto', padding: '20px' }}>
      <h1>Barcode Scanner Test</h1>
      <BarcodeScanner onResult={handleScanResult} />
    </div>
  );
};

export default TestScanner;
