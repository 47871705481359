import React from 'react';

export const ImageGrid: React.FC<{
  images: string[];
  loadedImages: Record<string, boolean>;
  errorImages: Record<string, boolean>;
  preloadImages: boolean;
}> = ({ images, loadedImages, errorImages, preloadImages }) => (
  <div
    style={{
      display: 'flex',
      overflowX: 'auto',
      maxWidth: '80vw',
      gap: '10px',
      alignItems: 'center',
    }}
  >
    {images.map((imageUrl, index) => (
      <div
        key={index}
        style={{
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <a
          href={imageUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: 'inherit',
            textDecoration: 'none',
            minWidth: '200px',
            minHeight: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {preloadImages &&
          !loadedImages[imageUrl] &&
          !errorImages[imageUrl] ? (
            <div>Loading...</div>
          ) : errorImages[imageUrl] ? (
            <div
              style={{
                color: '#666',
                textAlign: 'center',
                fontSize: '14px',
              }}
            >
              Failed to load image
            </div>
          ) : (
            <img
              src={imageUrl}
              alt={`Image ${index + 1}`}
              style={{
                height: '200px',
                width: 'auto',
                maxWidth: '300px',
                objectFit: 'contain',
              }}
            />
          )}
        </a>
        <small style={{ color: '#666' }}>
          Image {index + 1}
          {errorImages[imageUrl] && ' (Failed to load)'}
        </small>
      </div>
    ))}
  </div>
);
