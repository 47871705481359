import { SHIPPING_TYPES } from './shipping-types';

export type SupplierConfig = {
  id: string;
  abbreviation: string;
  datastore: string;
  supplier_type: 'DROPSHIP' | 'PICKUP';
  virtual_warehouse?: string;
  shippingTypes?: readonly string[];
  senders: string[];
};

export const SUPPLIERS: SupplierConfig[] = [
  {
    id: 'RockyMountain',
    abbreviation: 'RM',
    datastore: '/api/datastore/RockyMountainInventoryCacheDataStore',
    supplier_type: 'DROPSHIP',
    virtual_warehouse: 'rm_ds',
    shippingTypes: SHIPPING_TYPES.rm_dropship,
    senders: ['rm_ds_a', 'rm_ds_b', 'rm_ds_c', 'rm_ds_d', 'rm_ds_e'],
  },
  {
    id: 'PartsUnlimited',
    abbreviation: 'PU',
    datastore: '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
    supplier_type: 'DROPSHIP',
    virtual_warehouse: 'unknown',
    shippingTypes: SHIPPING_TYPES.dropship,
    senders: ['pu_ds_a', 'pu_ds_b', 'pu_ds_c', 'pu_ds_d', 'pu_ds_e'],
  },
  {
    id: 'RockyMountainPickupUt',
    abbreviation: 'RM',
    datastore: '/api/datastore/RockyMountainInventoryCacheDataStore',
    supplier_type: 'PICKUP',
    virtual_warehouse: 'pickup_ut',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_ut'],
  },
  {
    id: 'RockyMountainPickupKy',
    abbreviation: 'RM',
    datastore: '/api/datastore/RockyMountainInventoryCacheDataStore',
    supplier_type: 'PICKUP',
    virtual_warehouse: 'pickup_ky',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_ky'],
  },
  {
    id: 'PartsUnlimitedFast',
    abbreviation: 'PU',
    datastore: '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
    supplier_type: 'DROPSHIP',
    virtual_warehouse: 'pu_fast_ds',
    shippingTypes: SHIPPING_TYPES.standard,
    senders: [],
  },
  {
    id: 'PartsUnlimitedLong',
    abbreviation: 'PU',
    datastore: '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
    supplier_type: 'DROPSHIP',
    virtual_warehouse: 'pu_long_delay_ds',
    shippingTypes: SHIPPING_TYPES.standard,
    senders: [],
  },
  {
    id: 'PartsUnlimitedShort',
    abbreviation: 'PU',
    datastore: '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
    supplier_type: 'DROPSHIP',
    virtual_warehouse: 'pu_long_delay_ds',
    shippingTypes: SHIPPING_TYPES.standard,
    senders: [],
  },
  {
    id: 'PartsUnlimitedPickup',
    abbreviation: 'PU',
    datastore: '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
    supplier_type: 'PICKUP',
    virtual_warehouse: 'pickup_nc',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_nc', 'pickup_wi', 'pickup_cn'],
  },
  {
    id: 'TuckerRocky',
    abbreviation: 'TR',
    supplier_type: 'DROPSHIP',
    datastore: '/api/datastore/TuckerInventoryCacheDataStore',
    virtual_warehouse: 'tr_ds',
    shippingTypes: [
      ...SHIPPING_TYPES.standard,
      ...[
        'UPS Ground',
        'UPS Next Day Air',
        'UPS 2nd Day Air',
        'UPS 3 Day Select',
      ],
    ],
    senders: ['tr_ds_a', 'tr_ds_b', 'tr_ds_c', 'tr_ds_d', 'tr_ds_e'],
  },
  {
    id: 'TuckerRockyPickup',
    abbreviation: 'TR',
    supplier_type: 'PICKUP',
    datastore: '/api/datastore/TuckerInventoryCacheDataStore',
    virtual_warehouse: 'pickup_tx',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_tx'],
  },
  {
    id: 'Autodist',
    abbreviation: 'AU',
    supplier_type: 'DROPSHIP',
    datastore: '/api/datastore/AutodistInventoryCacheDataStore',
    virtual_warehouse: 'au_ds',
    shippingTypes: [
      'FedEx Ground',
      'FedEx SmartPost',
      'FedEx Express Saver',
      'FedEx Home Delivery',
      'FedEx International Economy',
      'FedEx International Priority',
      'FedEx Priority Overnight',
      'FedEx Priority Saturday',
      'FedEx Standard Overnight',
      'FedEx 2Day',
      'UPS Ground',
      'UPS Next Day Air',
      'UPS Next Day Air Early',
      'UPS Next Day Air Saver',
      'UPS SurePost',
      'UPS 2nd Day Air',
      'UPS 2nd Day Air A.M.',
      'UPS 3 Day Select',
      'USPS Parcel Select',
      'USPS Priority Mail',
      'USPS Ground Advantage',
    ],
    senders: ['au_ds_a', 'au_ds_b', 'au_ds_c', 'au_ds_d', 'au_ds_e'],
  },
  {
    id: 'RollunOfficePickupKy',
    abbreviation: 'OFC',
    supplier_type: 'PICKUP',
    datastore: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    virtual_warehouse: 'pickup_ky_ROF',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_ky_rof'],
  },
  {
    id: 'RollunOfficePickupTx',
    abbreviation: 'OFC',
    supplier_type: 'PICKUP',
    datastore: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    virtual_warehouse: 'pickup_tx_ROF',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_tx_rof'],
  },
  {
    id: 'RollunOfficePickupNy',
    abbreviation: 'OFC',
    supplier_type: 'PICKUP',
    datastore: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    virtual_warehouse: 'pickup_ny_ROF',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_ny_rof'],
  },
  {
    id: 'RollunOfficePickupWi',
    abbreviation: 'OFC',
    supplier_type: 'PICKUP',
    datastore: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    virtual_warehouse: 'pickup_wi_ROF',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_wi_rof'],
  },
  {
    id: 'RollunOfficePickupCn',
    abbreviation: 'OFC',
    supplier_type: 'PICKUP',
    datastore: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    virtual_warehouse: 'pickup_cn_ROF',
    shippingTypes: SHIPPING_TYPES.pickup,
    senders: ['pickup_cn_rof'],
  },
  {
    id: 'Slt',
    abbreviation: 'SLT',
    datastore: '/api/datastore/SltInventoryCacheDataStore',
    supplier_type: 'DROPSHIP',
    senders: [],
  },
  {
    id: 'Wps',
    abbreviation: 'WPS',
    datastore: '/api/datastore/WpsInventoryCacheDataStore',
    supplier_type: 'DROPSHIP',
    virtual_warehouse: 'wps_ds',
    shippingTypes: SHIPPING_TYPES.standard,
    senders: ['wps_ds_a', 'wps_ds_b', 'wps_ds_c', 'wps_ds_d', 'wps_ds_e'],
  },
  {
    id: 'Turn14',
    abbreviation: 'TFT',
    datastore: '/api/datastore/TurnInventoryCacheDataStore',
    supplier_type: 'DROPSHIP',
    virtual_warehouse: 'tft_ds',
    shippingTypes: SHIPPING_TYPES.tft_dropship,
    senders: ['tft_ds_a', 'tft_ds_b', 'tft_ds_c', 'tft_ds_d', 'tft_ds_e'],
  },
];

export const suppliers = [
  'RockyMountain',
  'RollunOffice',
  'Autodist',
  'Wps',
  'PartsUnlimited',
  'Turn14',
  'other',
];
