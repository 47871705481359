import React from 'react';
import AbstractEditor, { IEditorProps } from './AbstractEditor';
import Dialog from '@material-ui/core/Dialog';
import { JsonEditor as JSONEditor } from 'json-edit-react';
import { DialogActions, DialogTitle } from '@material-ui/core';
import MuiButton from '../../../../../../UI/MuiButton';

interface IState {
  obj: any;
}

class JsonEditor extends AbstractEditor<IState> {
  constructor(props: IEditorProps) {
    super(props);
    this.state = {
      obj:
        typeof props.value === 'string' ? JSON.parse(props.value) : props.value,
    };
  }

  getValue = () => {
    return { [this.props.column.key]: this.state.obj };
  };

  getInputNode(): HTMLInputElement | null | undefined {
    return undefined;
  }

  onInputChange = (obj: any) => {
    this.setState({ obj });
  };

  banParentKeyListeners = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;
    if (key === 'Enter' || key === 'Tab' || key.indexOf('Arrow') > -1) {
      e.stopPropagation();
    }
  };

  render() {
    const { onCommit, onCommitCancel } = this.props;
    const { obj } = this.state;
    return (
      <div
        onBlur={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          this.banParentKeyListeners(e);
        }}
      >
        <Dialog
          onClose={() => {
            return;
          }}
          open={true}
          fullWidth
        >
          <DialogTitle>JSON Editor</DialogTitle>
          <JSONEditor
            restrictDrag={false}
            data={this.state.obj}
            onUpdate={(data) => this.setState({ obj: data.newData })}
            enableClipboard
          />
          <DialogActions>
            <MuiButton
              onClick={() => {
                onCommit(obj);
                onCommitCancel();
              }}
              color="success"
            >
              Ok
            </MuiButton>
            <MuiButton color="error" onClick={() => onCommitCancel()}>
              Cancel
            </MuiButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default JsonEditor;
