import { useState, useEffect } from 'react';

interface UseImageLoadProps {
  src: string | string[];
  preloadImages?: boolean;
  maxPreloadImages?: number;
}

interface UseImageLoadResult {
  loadedImages: Record<string, boolean>;
  errorImages: Record<string, boolean>;
}

export const isValidImageUrl = (url: string): boolean => {
  if (!url || url === '-1' || url === 'null') return false;

  try {
    return (
      url.startsWith('http://') ||
      url.startsWith('https://') ||
      url.startsWith('/')
    );
  } catch {
    return false;
  }
};

export const useImageLoad = ({
  src,
  preloadImages = false,
  maxPreloadImages = 10,
}: UseImageLoadProps): UseImageLoadResult => {
  const [loadedImages, setLoadedImages] = useState<Record<string, boolean>>({});
  const [errorImages, setErrorImages] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const imageElements: HTMLImageElement[] = [];
    const imagesToLoad = Array.isArray(src)
      ? preloadImages
        ? src.slice(0, maxPreloadImages)
        : src
      : isValidImageUrl(src)
      ? [src]
      : [];

    imagesToLoad.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        setLoadedImages((prev) => ({
          ...prev,
          [imageUrl]: true,
        }));
        setErrorImages((prev) => ({
          ...prev,
          [imageUrl]: false,
        }));
      };

      img.onerror = () => {
        setErrorImages((prev) => ({
          ...prev,
          [imageUrl]: true,
        }));
      };

      imageElements.push(img);
    });

    return () => {
      imageElements.forEach((img) => {
        img.onload = null;
        img.onerror = null;
        img.src = '';
      });
      setLoadedImages({});
      setErrorImages({});
    };
  }, [src, preloadImages, maxPreloadImages]);

  return { loadedImages, errorImages };
};
