export const APP_TYPES = [
  'grid',
  'BarcodeApp',
  'cardPicker',
  'ControlCenter',
  'BrandsMapper',
  'ServiceConstructor',
  'EditableTree',
  'ACLRulesEditor',
  'ParcelApp',
  /* ,
	'DataHandlerEditor' ,
	'ProviderTree' ,
	'SchemaEditor__test' ,
	'EditServiceConfig'*/
];

// TODO make single map with name: Renderer for editor and actual Table

export type RendererName = typeof RENDERERS_NAMES[number];

export const RENDERERS_NAMES = [
  'empty',
  'datetime',
  'stringDate',
  'json',
  'link',
  'imageLink',
  'arrayImageLink',
  'status',
  'bool',
] as const;

export const EDITORS_NAMES = ['empty', 'calendar', 'json', 'manager_comment'];

export const VALIDATORS_NAMES = ['regexp', 'positive_int'];

export const CONFIG_JSON_SCHEMA = {
  definitions: {},
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: 'http://example.com/root.json',
  type: 'object',
  title: 'The Root Schema',
  required: ['appName', 'appPath', 'appType', 'appParams'],
  additionalProperties: false,
  properties: {
    appName: {
      $id: '#/properties/appName',
      type: 'string',
      title: 'The Appname Schema',
      default: '',
      examples: ['name'],
      pattern: '^(.*)$',
    },
    appPath: {
      $id: '#/properties/appPath',
      type: 'string',
      title: 'The Apppath Schema',
      default: '',
      examples: ['/path-name'],
      pattern: '^(.*)$',
    },
    appType: {
      $id: '#/properties/appType',
      type: 'string',
      title: 'The Apptype Schema',
      default: '',
      examples: ['grid'],
      pattern: '^(.*)$',
    },
    appParams: {
      $id: '#/properties/appParams',
      type: 'object',
      title: 'The Appparams Schema',
      required: [],
      properties: {
        hooks: {
          $id: '#/properties/appParams/properties/hooks',
          type: 'array',
          title: 'The Hooks Schema',
          items: {
            $id: '#/properties/appParams/properties/hooks/items',
            type: 'object',
            title: 'The Items Schema',
            required: ['url', 'name'],
            properties: {
              url: {
                $id:
                  '#/properties/appParams/properties/hooks/items/properties/url',
                type: 'string',
                title: 'The Url Schema',
                default: '',
                examples: ['/api/webhook/selectedItemsReport'],
                pattern: '^(.*)$',
              },
              name: {
                $id:
                  '#/properties/appParams/properties/hooks/items/properties/name',
                type: 'string',
                title: 'The Name Schema',
                default: '',
                examples: ['Selected items report'],
                pattern: '^(.*)$',
              },
              dontWaitUntilResolve: {
                $id:
                  '#/properties/appParams/properties/hooks/items/properties/dontWaitUntilResolve',
                type: 'boolean',
                title: 'The Dontwaituntilresolve Schema',
                default: false,
                examples: [true],
              },
            },
          },
        },
        datastoreUrl: {
          $id: '#/properties/appParams/properties/datastoreUrl',
          type: 'string',
          title: 'The Datastoreurl Schema',
          default: '',
          examples: ['/api/datastore/PrepareJeffTableDataStore'],
          pattern: '^(.*)$',
        },
        fieldsConfig: {
          $id: '#/properties/appParams/properties/fieldsConfig',
          type: 'array',
          title: 'The Fieldsconfig Schema',
          items: {
            $id: '#/properties/appParams/properties/fieldsConfig/items',
            type: 'object',
            title: 'The Items Schema',
            required: ['name'],
            properties: {
              name: {
                $id:
                  '#/properties/appParams/properties/fieldsConfig/items/properties/name',
                type: 'string',
                title: 'The Name Schema',
                default: '',
                examples: ['config'],
                pattern: '^(.*)$',
              },
              minWidth: {
                $id:
                  '#/properties/appParams/properties/fieldsConfig/items/properties/minWidth',
                type: 'integer',
                title: 'The Minwidth Schema',
                default: 0,
                examples: [100],
              },
              isEditable: {
                $id:
                  '#/properties/appParams/properties/fieldsConfig/items/properties/isEditable',
                type: 'boolean',
                title: 'The Iseditable Schema',
                default: false,
                examples: [false],
              },
              rendererName: {
                $id:
                  '#/properties/appParams/properties/fieldsConfig/items/properties/rendererName',
                type: 'string',
                title: 'The Renderername Schema',
                default: '',
                examples: ['json'],
                pattern: '^(.*)$',
              },
              editorName: {
                $id:
                  '#/properties/appParams/properties/fieldsConfig/items/properties/editorName',
                type: 'string',
                title: 'The Editorname Schema',
                default: '',
                examples: ['json'],
                pattern: '^(.*)$',
              },
            },
          },
        },
        idField: {
          $id: '#/properties/appParams/properties/idField',
          type: 'string',
          title: 'The Idfield Schema',
          default: '',
          examples: ['sku'],
          pattern: '^(.*)$',
        },
        enableDeleteItem: {
          $id: '#/properties/appParams/properties/enableDeleteItem',
          type: 'boolean',
          title: 'The Enabledeleteitem Schema',
          default: false,
          examples: [true],
        },
        enableDeleteAll: {
          $id: '#/properties/appParams/properties/enableDeleteAll',
          type: 'boolean',
          title: 'The Enabledeleteall Schema',
          default: false,
          examples: [true],
        },
        pasteUploaderParams: {
          $id: '#/properties/appParams/properties/pasteUploaderParams',
          type: 'object',
          title: 'The Pasteuploaderparams Schema',
          required: ['fieldNames'],
          properties: {
            fieldNames: {
              $id:
                '#/properties/appParams/properties/pasteUploaderParams/properties/fieldNames',
              type: 'array',
              title: 'The Fieldnames Schema',
            },
            noId: {
              $id:
                '#/properties/appParams/properties/pasteUploaderParams/properties/noId',
              type: 'boolean',
              title: 'The Noid Schema',
              default: false,
              examples: [true],
            },
            validators: {
              $id:
                '#/properties/appParams/properties/pasteUploaderParams/properties/validators',
              type: 'array',
              title: 'The Validators Schema',
              items: {
                $id:
                  '#/properties/appParams/properties/pasteUploaderParams/properties/validators/items',
                type: 'object',
                title: 'The Items Schema',
                required: ['type', 'field', 'param'],
                properties: {
                  type: {
                    $id:
                      '#/properties/appParams/properties/pasteUploaderParams/properties/validators/items/properties/type',
                    type: 'string',
                    title: 'The Type Schema',
                    default: '',
                    examples: ['regexp'],
                    pattern: '^(.*)$',
                  },
                  field: {
                    $id:
                      '#/properties/appParams/properties/pasteUploaderParams/properties/validators/items/properties/field',
                    type: 'string',
                    title: 'The Field Schema',
                    default: '',
                    examples: ['order_number_from_supplier'],
                    pattern: '^(.*)$',
                  },
                  param: {
                    $id:
                      '#/properties/appParams/properties/pasteUploaderParams/properties/validators/items/properties/param',
                    type: 'string',
                    title: 'The Param Schema',
                    default: '',
                    examples: ['^[0-9]+$'],
                    pattern: '^(.*)$',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export const CONFIG_EXAMPLE = `
{
    "appName": "name", - mandatory
    "appPath": "/path-name", - mandatory
    "appType": "grid", - mandatory -> on of ( gridWithHooks | grid | BarcodeApp | ParcelApp |
                                              cardPicker | ControlCenter | ServiceConstructor |
                                              BrandsMapper| EditableTree )

    "appParams": {
        "gridParams": {
             "hooks":[{
                     "url":"/api/webhook/selectedItemsReport",
                     "name":"Selected items report",
                     "dontWaitUntilResolve": true - (true|false)
             }],
             "defaultQuery": "limit(20)", - rql query, that will be used as default for table,
             "datastoreUrl": "/api/datastore/PrepareJeffTableDataStore",
             "urlFromPathParam": true, - url will be generated by adding "datastoreUrl" and id property from react router's match.
             "fieldsConfig": [{
                 "name":"config",
                 "minWidth": 100,
                 "isEditable": false, - (true|false)
                 "rendererName":"json" - one of (json|datetime|stringDate|status|link)
                 "editorName": "json" - one of (json|calendar)
             }],
             "idField": "sku",
             "enableDeleteItem": true, - (true|false)
             "enableDeleteAll": true, - (true|false)
             "pasteUploaderParams": {
                 "fieldNames": ["field_1", "field_2"],
                 "noId": true, - (true|false) if true, in pasted data first column will be removed
                 "validators": [
                    {
                         "type":"regexp", - one of (regexp|positive_int)
                         "field":"order_number_from_supplier",
                         "param":"^[0-9]+$" - if type is 'regexp', params with regexp is mandatory
                     },
                     {
                         "type":"positive_int",
                         "field":"quantity"
                     }
                 ]
             }
         }
     }
}
`;

export const SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS = {
  ADD_ROUTE: {
    PATH: 'The route of the endpoint, by which you can access this endpoint',
    NAME:
      'The name of the route, by which the route and resource are connected',
    MIDDLEWARE:
      'The middlewares, that will handle the requests to this endpoint',
    METHODS: 'The methods, that this endpoint can handle',
  },

  ADD_RESOURCE: {
    NAME: 'The name of the resource, the same as name of route',
    PATTERN: 'The pattern to find resource name',
    PARENT_ID: 'The id of parent resource',
  },
};
