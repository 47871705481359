import React from 'react';
import { createPortal } from 'react-dom';

interface ImageTooltipProps {
  show: boolean;
  popperRef: any;
  styles: any;
  attributes: any;
  onMouseEnter: () => void;
  onMouseLeave: (e: React.MouseEvent) => void;
  children: React.ReactNode;
}

export const ImageTooltip: React.FC<ImageTooltipProps> = ({
  show,
  popperRef,
  styles,
  attributes,
  onMouseEnter,
  onMouseLeave,
  children,
}) => {
  if (!show) return null;

  return createPortal(
    <div
      ref={popperRef}
      style={{
        ...styles.popper,
        zIndex: 9999,
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        padding: '8px',
      }}
      {...attributes.popper}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>,
    document.body,
  );
};
